.DownloadButtons {
  display: flex;
  justify-content: center;
}

.DownloadButtons Button {
  margin-left: 10%;
  margin-right: 10%;
}

.UsersP p {
  color: rgb(223, 219, 219);
  font-weight: bold;
  font-size: large;
}

.VideoContainer {
  padding-top: 3em;
}

.VideoContainer h2 {
  color: rgb(223, 219, 219);
  font-weight: bold;
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-self: center;
}

.VideoContainer Col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EnjoyExploreSubscribe h2 {
  color: rgb(223, 219, 219);
  font-weight: bold;
  font-size: 3em;
  justify-content: center;
  align-self: center;
}

.EnjoyExploreSubscribe p {
  color: rgb(223, 219, 219);
  font-weight: normal;
  font-size: large;
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 5%;
}

.EnjoyExploreSubscribe {
  margin-top: 3em;
}
