.home p {
  color: rgb(223, 219, 219);
}

.home h2 {
  font-weight: bold;
  font-size: 3rem;
  color: rgb(223, 219, 219);
}

.textCol {
  padding: 0%;
}

.home img {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
