.PageButton {
  background-color: #27d1fc;
  color: #1a1919;
  font-weight: bold;
  border-color: #27d1fc;
  border-radius: 20px;
  height: 3rem;

  align-items: center;
}

.PageButton:hover {
  transform: scale(1.05);
  background-color: #27fcbd;
  border-color: #27fcbd;
}
