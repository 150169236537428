.HowItWorks h2 {
  font-weight: bold;
  font-size: 3rem;
  color: rgb(223, 219, 219);
}

.BusinessList h3 {
  font-weight: bold;
  font-size: 2rem;
  color: rgb(223, 219, 219);
}

.BusinessList p {
  font-weight: normal;
  font-size: 1rem;
  color: rgb(223, 219, 219);
}

.List h3 {
  font-weight: bold;
  font-size: 2rem;
  color: rgb(223, 219, 219);
}

.List p {
  font-weight: normal;
  font-size: 1rem;
  color: rgb(223, 219, 219);
}
